// @flow

import "unfetch/polyfill";
import * as React from "react";
import ReactDOM from "react-dom";
// $FlowFixMe
import { fromJS } from "immutable";
import { LicenseManager } from "@ag-grid-enterprise/core";
import { AppContainer } from "react-hot-loader";
import createHashHistory from "history/createHashHistory";
import { MMPConfig } from "@deepintent/react-component-marketplace";
import { GoogleTagManager } from "@deepintent/react-utils";
import ConfigManager from "./utils/manager/ConfigManager";
import routes from "./routes";
import Root from "./containers/Root";
import * as serviceWorker from "./webworkers/registerServiceWorker";
import EventPublisherManager from "./utils/manager/EventPublisherManager";
import LoggerManager from "./utils/manager/LoggerManager";
import IntercomManager from "./utils/manager/IntercomManager";
import { hasOwnProperty } from "./utils/utils";
import "../style/index.scss";

let initialState = {};

const loggerManager = new LoggerManager();
loggerManager.init();

// initialize ConfigManager
new ConfigManager().init();

const eventPublisherManager = new EventPublisherManager();
eventPublisherManager.init();

const mmpConfig = new MMPConfig();
mmpConfig.init({
	eventPublisherManager: EventPublisherManager.getContext(),
	loggerManager: LoggerManager.getContext(),
});

const intercomManager = new IntercomManager();
intercomManager.init();

// set ag-grid license key
LicenseManager.setLicenseKey(process.env.AG_GRID_KEY);

// add GTM scripts
const tagManagerArgs = { ...ConfigManager.getContext().gtmConfig };
GoogleTagManager.initialize(tagManagerArgs);

// rehydrate initialState for JS app
if (window.__INITIAL_STATE__) {
	initialState = window.__INITIAL_STATE__;
	// Transform into Immutable.js collections,
	// but leave top level keys untouched for Redux
	Object.keys(initialState).forEach((key) => {
		initialState[key] = fromJS(initialState[key]);
	});
}

const history = createHashHistory();

// Render the React application to the DOM
// Root component is to bootstrap Provider, Router and DevTools

const DOM = () => (
	<AppContainer>
		<Root history={history} />
	</AppContainer>
);

const element = document.getElementById("root");

if (element) {
	ReactDOM.render(<DOM />, element);
}

if (
	module &&
	hasOwnProperty(module, "hot") &&
	// $FlowFixMe
	hasOwnProperty(module.hot, "accept") &&
	typeof module.hot === "function"
) {
	module.hot.accept("./containers/Root", () => {
		const NextRoot = require("./containers/Root").default; // eslint-disable-line global-require
		const element = document.getElementById("root");
		if (element) {
			ReactDOM.render(
				<AppContainer>
					<NextRoot history={history} />
				</AppContainer>,
				element
			);
		}
	});
}

serviceWorker.register();
