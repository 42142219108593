// @flow

import React from "react";
import {
	DiAdTagIcon,
	DiAudiencesIcon,
	DiCreativesIcon,
	DiDashboardIcon,
	DiDashboardFilled,
	DiDealsIcon,
	DiIMRIcon,
	DiNewSettingsIcon,
	DiOpenAuctionV2,
	DiOpenAuctionFilled,
	DiAdTagFilled,
	DiAudienceFilled,
	DiDealsFilled,
	DiCreativesFilled,
	DiInventoryV3Icon,
	DiInventoryFilled,
	theme,
} from "@deepintent/react-component-lib-core";
import FeatureAuthorisationFlag from "../enums/FeatureAuthorisationFlag";

const { PUBLISH_RX, DASHBOARD, INVENTORY, AD_TAG, AUDIENCE, DIRECT_AUCTION, DEALS, CREATIVE_LIBRARY } =
	FeatureAuthorisationFlag;

export const SIDE_NAV_CONFIG = [
	{
		id: "integration",
		menuItems: [
			{
				id: "dashboard",
				label: "Dashboard",
				iconComponent: <DiDashboardIcon />,
				authKey: DASHBOARD.value,
				selectedIconComponent: (
					<DiDashboardFilled
						grandientColorStart={theme.colors.filledIconColor3}
						grandientColorEnd={theme.colors.filledIconColor4}
					/>
				),
			},
			{
				id: "outcomes",
				label: "Outcomes",
				iconComponent: <DiIMRIcon />,
				authKey: PUBLISH_RX.value,
			},
			{
				id: "publishRx",
				label: "Settings",
				iconComponent: <DiNewSettingsIcon />,
				authKey: PUBLISH_RX.value,
			},
			{
				id: "inventory",
				label: "Inventory",
				authKey: INVENTORY.value,
				iconComponent: <DiInventoryV3Icon />,
				selectedIconComponent: (
					<DiInventoryFilled
						grandientColorStart={theme.colors.filledIconColor3}
						grandientColorEnd={theme.colors.filledIconColor4}
					/>
				),
			},
			{
				id: "adtag",
				label: "Ad Tag",
				authKey: AD_TAG.value,
				iconComponent: <DiAdTagIcon />,
				selectedIconComponent: <DiAdTagFilled />,
			},
			{
				id: "audience",
				label: "Audience",
				authKey: AUDIENCE.value,
				iconComponent: <DiAudiencesIcon />,
				selectedIconComponent: (
					<DiAudienceFilled
						grandientColorStart={theme.colors.filledIconColor3}
						grandientColorEnd={theme.colors.filledIconColor4}
					/>
				),
			},
		],
	},
	{
		id: "marketplace",
		menuItems: [
			{
				id: "directAuction",
				label: "Direct Auction",
				authKey: DIRECT_AUCTION.value,
				iconComponent: <DiOpenAuctionV2 />,
				selectedIconComponent: <DiOpenAuctionFilled />,
			},
			{
				id: "deals",
				label: "Deals",
				authKey: DEALS.value,
				iconComponent: <DiDealsIcon />,
				selectedIconComponent: (
					<DiDealsFilled
						grandientColorStart={theme.colors.filledIconColor3}
						grandientColorEnd={theme.colors.filledIconColor4}
					/>
				),
			},
			{
				id: "creativeLibrary",
				label: "Creative Library",
				authKey: CREATIVE_LIBRARY.value,
				iconComponent: <DiCreativesIcon />,
				selectedIconComponent: (
					<DiCreativesFilled
						grandientColorStart={theme.colors.filledIconColor3}
						grandientColorEnd={theme.colors.filledIconColor4}
					/>
				),
			},
		],
	},
];
const isFeatureAuthorised = (featureAuthorisationFlag: string, authorisationConfig: Object) => {
	return !!authorisationConfig?.[featureAuthorisationFlag];
};

export const getMenuSections = (authorisationConfig: MenuFeaturesAuthorisationConfig): SideNavConfigType => {
	const menuSections = SIDE_NAV_CONFIG.map((sideNavItem) => {
		const menuItems = sideNavItem.menuItems.filter((menuItem) => {
			return isFeatureAuthorised(menuItem.authKey, authorisationConfig);
		});
		return {
			id: sideNavItem.id,
			menuItems,
		};
	});

	return menuSections;
};

// This util calculates which is the first allowed route for the user
export const getFirstAuthorizedRoute = ({ menuFeaturesAuthorisationConfig }: Object): ?string => {
	for (let idx1 = 0; idx1 < SIDE_NAV_CONFIG.length; idx1 += 1) {
		const { menuItems } = SIDE_NAV_CONFIG[idx1];
		for (let idx2 = 0; idx2 < menuItems.length; idx2 += 1) {
			if (menuFeaturesAuthorisationConfig?.[menuItems[idx2].authKey]) {
				return menuItems[idx2].id;
			}
		}
	}
	return null;
};
