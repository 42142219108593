import React, { useEffect, useState } from "react";
import Constant from "../utils/Constants";
import { matchRoute, normalizePath } from "../utils/utils";

const { ID_TO_PATH_MAPPING } = Constant;
// Convert ID_TO_PATH_MAPPING into an array of paths
const validRoutes = Object.values(ID_TO_PATH_MAPPING);

const EnhancedRouteCheck = ({ children }) => {
  const [isValidRoute, setValidRoute] = useState(true);

  const checkValidRoute = () => {
    // Normalize the current path and remove extra slashes before checking
    const currentPath = normalizePath(window.location.hash.slice(1).split("?")[0]);
    const isValid = validRoutes.some(route => matchRoute(currentPath, route));
    setValidRoute(isValid);
  };

  useEffect(() => {
    checkValidRoute();
    window.addEventListener("hashchange", checkValidRoute);
    return () => {
      window.removeEventListener("hashchange", checkValidRoute);
    };
  }, []);

  return children({ isValidRoute });
};

export default EnhancedRouteCheck;
