// @flow

import React, { Component, lazy, Suspense } from "react";
import type { Node } from "react";
import { IntlProvider } from "react-intl";
import { DiPageLevelError } from "@deepintent/react-component-lib-core";
import { EnhancedCachedClientDetailsQuery } from "@deepintent/react-component-internal";
import { ApolloConsumer } from "react-apollo";
import { ApolloClient } from "apollo-client";
import type { ClientDetails } from "../types";
import ConfigManager from "../utils/manager/ConfigManager";
import FullscreenLoader from "../components/shared/FullscreenLoader/FullscreenLoader";
import DiNetworkAdapter from "../environments/DiNetworkAdapter";
import NotificationManager from "./NotificationManager/NotificationManager";
import AppWrapper from "./HomeContainer/AppWrapper";
import NoActiveResourceOverlay from "../components/shared/NoActiveResourceOverlay/NoActiveResourceOverlay";
import clearItemsFromCache from "../utils/apolloCacheUtils";
import GracefulApiHandling from "./GracefulApiHandling";
import "bootstrap/dist/css/bootstrap.min.css";

type Props = {
	children: Node,
};

type State = {
	isErrored: boolean,
	activeResourceId: number,
};

const AppWrapperWithLDProvider = lazy(() => import("./HomeContainer/AppWrapperWithLD"));
const AppWrapperWithoutLDProvider = lazy(() => import("./HomeContainer/AppWrapper"));

export default class App extends Component<Props, State> {
	state = {
		isErrored: false,
		activeResourceId: -1,
	};

	clientDetailsSubTree = (apolloClient: ApolloClient, clientDetails: ?ClientDetails) => {
		const { activeResourceId, isErrored } = this.state;
		const { children } = this.props;
		const areClientDetailsPresent =
			clientDetails &&
			Number(clientDetails.activeOrganizationId) > -1 &&
			Number(clientDetails.activeResourceId) > -1;
		const clientDetailsChanged = areClientDetailsPresent && activeResourceId !== clientDetails.activeResourceId;
		if (clientDetailsChanged) {
			this.setState(
				{
					activeResourceId: clientDetails.activeResourceId,
				},
				() => {
					if (activeResourceId === -1) {
						/* Don't refetch the observable queries if the app is loaded for
					   	   the first time only refetch them when advertiser is changed.
						*/
						clearItemsFromCache(apolloClient);
					}
				}
			);
		}
		return (
			<NoActiveResourceOverlay
				messageText="No Publisher linked to your account."
				subtext="Please contact your administrator."
				clientDetails={clientDetails}
			>
				{isErrored ? <DiPageLevelError /> : children}
			</NoActiveResourceOverlay>
		);
	};

	renderApolloConsumerChildren = (client: ApolloClient) => {
		return (
			<EnhancedCachedClientDetailsQuery>
				{this.clientDetailsSubTree.bind(this, client)}
			</EnhancedCachedClientDetailsQuery>
		);
	};

	componentDidCatch() {
		this.setState({
			isErrored: true,
		});
	}

	getApolloConsumer() {
		return <ApolloConsumer>{this.renderApolloConsumerChildren}</ApolloConsumer>;
	}

	render() {
		const urlParams = new URLSearchParams(this.props?.location?.search);
		const qaParamsExists = urlParams?.get("qa");
		const isAppEnvDev = ConfigManager.getContext()?.appEnv === "development";

		//  Do not connect to launch darkly
		//  1. If qa Query Param is present in the URL
		//  2. appEnv is development
		const stopLDConnection = qaParamsExists && isAppEnvDev;

		return (
			<DiNetworkAdapter>
				<IntlProvider locale="en">
					<>
						<NotificationManager />
						<Suspense fallback={<FullscreenLoader />}>
							{stopLDConnection ? (
								<AppWrapperWithoutLDProvider>{this.getApolloConsumer()}</AppWrapperWithoutLDProvider>
							) : (
								<AppWrapperWithLDProvider>{this.getApolloConsumer()}</AppWrapperWithLDProvider>
							)}
						</Suspense>
						<GracefulApiHandling />
					</>
				</IntlProvider>
			</DiNetworkAdapter>
		);
	}
}
