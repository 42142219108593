import React from "react";
import { useHistory } from "react-router-dom";
import {
	ButtonTypeEnum,
	DiButton,
	DiSupportIcon,
	DiTextLogo,
	DiTypography,
} from "@deepintent/react-component-lib-core";
import { LoginUtils } from "@deepintent/react-component-internal";
import Constant from "../../../utils/Constants";
import ConfigManager from "../../../utils/manager/ConfigManager";
import style from "./PageNotFound.module.scss";

const { DATA_DI_ID, PAGE_NOT_FOUND, ID_TO_PATH_MAPPING } = Constant;
const { OOPS_TEXT, PAGE_REMOVED, EMAIL_ID, HELP_CENTER, SUPPORT_WIDGET, DSP, HELP_CENTER_URL } = PAGE_NOT_FOUND;

const renderPageNotFoundHeader = (goToDefaultPage) => (
	<div
		className={style.diLogoContainer}
		onClick={goToDefaultPage}
		onKeyDown={(e) => e.key === "Enter" && goToDefaultPage()}
		role="button"
		tabIndex={0}
		{...{ [DATA_DI_ID]: "page-not-found-diTextLogo" }}
	>
		<DiTextLogo width={140} height={28} />
	</div>
);

const renderPageNotFoundBody = (goToHelpCenter) => (
	<div className={style.pageNotFoundTextContainer}>
		<DiTypography className={style.headerText} text={OOPS_TEXT} />
		<div className={style.pageNotFoundDescContainer}>
			<DiTypography type="header4" className={style.detailText}>
				<span>{PAGE_REMOVED}</span>
				<span className={style.addressText}>{EMAIL_ID}</span>
				<span>{HELP_CENTER}</span>
			</DiTypography>
		</div>
		<DiTypography type="header4" className={style.helpCenterText}>
			<span>{SUPPORT_WIDGET}</span>
			<DiSupportIcon {...{ [DATA_DI_ID]: "page-not-found-support-icon" }} className={style.supportIcon} />
			<span>{DSP}</span>
		</DiTypography>
		<DiButton
			type={ButtonTypeEnum.PRIMARY.value}
			text="Visit Help Center"
			onClick={goToHelpCenter}
			{...{ [DATA_DI_ID]: "page-not-found-help-center-button" }}
		/>
	</div>
);

const PageNotFound = () => {
	const history = useHistory();

	const goToHelpCenter = () => {
		window.open(HELP_CENTER_URL, "_blank");
	};

	const goToDefaultPage = () => {
		const defaultPath = ID_TO_PATH_MAPPING.dashboard;
		history.push(defaultPath);
	};

	// check if session is active and then only load "Page Not Found"
	const config = ConfigManager.getContext();
	const loginUtils = new LoginUtils(config);
	const token = loginUtils.getToken();

	if (!token) {
		loginUtils.logout();
		return null;
	}

	return (
		<div className={style.pageNotFoundContainer}>
			{renderPageNotFoundHeader(goToDefaultPage)}
			{renderPageNotFoundBody(goToHelpCenter)}
		</div>
	);
};

export default PageNotFound;
