// @flow

import React, { useEffect, useState } from "react";
import { usePrevious } from "@deepintent/react-utils";
import isEqual from "lodash/isEqual";
import classNames from "classnames";
import { DiSidebar, DiSidebarMenu } from "@deepintent/react-component-lib-core";
import { withRouter } from "react-router";
import { LoginUtils, UserDropdown } from "@deepintent/react-component-internal";
import { getMenuSections } from "../../utils/menuSectionUtils";
import ConfigManager from "../../utils/manager/ConfigManager";
import IntercomManager from "../../utils/manager/IntercomManager";
import AppContext from "../../containers/HomeContainer/AppContext";
import type { DiSidebarMenuItem } from "@deepintent/react-component-lib-core";
import Constant from "../../utils/Constants";
import { hasOwnProperty, isArrayNotEmpty } from "../../utils/utils";
import "./sideNav.scss";

type ProvidedProps = {
	history: Object,
	location: Object,
};

type Props = {
	application: string,
	style?: Object,
	className?: string,
	children?: React.Node,
};

const { ID_TO_PATH_MAPPING, PUBLISHER_APP_VERSION } = Constant;
const DEFAULT_ROUTE_ID = "dashboard";

const SideNav = (props: Props & ProvidedProps) => {
	const [open, setOpen] = useState<boolean>(false);
	const [selectedMenuItemIds, setSelectedMenuItemIds] = useState<Array<string>>([DEFAULT_ROUTE_ID]);
	const prevProps = usePrevious<ProvidedProps & Props>(props);

	const logout = () => {
		const loginUtils = new LoginUtils(ConfigManager.getContext());
		loginUtils.logout();

		IntercomManager.shutdown();
	};

	const openSidebar = (event: SyntheticEvent<>) => {
		if (!open) {
			setOpen(true);
		}
	};

	const closeSidebar = (event: SyntheticEvent<>) => {
		if (open) {
			setOpen(false);
		}
	};

	const onMenuItemClicked = (selectedMenuItem: DiSidebarMenuItem) => {
		const updatedSelectedMenuItemIds = selectedMenuItem.id;
		const { history } = props;

		if (updatedSelectedMenuItemIds) {
			history.push(ID_TO_PATH_MAPPING[updatedSelectedMenuItemIds]);
		}

		setSelectedMenuItemIds([updatedSelectedMenuItemIds]);
	};

	const { application, style, className } = props;

	const sideNavClassName = classNames(className, "side-panel", {
		"side-panel-collapsed": !open,
	});

	useEffect(() => {
		// when location changes, update selection
		if (!isEqual(prevProps, props)) {
			const updatedRoute = SideNav.getCurrentRoute(props);
			setSelectedMenuItemIds([updatedRoute]);
		}
	});

	return (
		<AppContext.Consumer>
			{({ menuFeaturesAuthorisationConfig }) => {
				const menuSections = getMenuSections(menuFeaturesAuthorisationConfig);
				const sidebarMenuSections = menuSections || [];

				return (
					<div className="sidebar-wrapper">
						<div className={sideNavClassName} style={style}>
							<DiSidebar
								open={open}
								collapseType="compact"
								onOpen={openSidebar}
								onClose={closeSidebar}
								application={application}
								rootClassName="sidebar-root"
							>
								<DiSidebarMenu
									idToPathMapping={ID_TO_PATH_MAPPING}
									application={application}
									open={open}
									menuSections={sidebarMenuSections}
									selectedMenuItemIds={selectedMenuItemIds}
									onMenuItemClicked={onMenuItemClicked}
									triggerActionDom={null}
									enableExpandCollapseOnHover
									classes={{ menuItemWrapper: "menu-item-wrapper" }}
								/>
							</DiSidebar>
						</div>
					</div>
				);
			}}
		</AppContext.Consumer>
	);
};

SideNav.getCurrentRoute = (props: ProvidedProps & Props): string => {
	const { location } = props;
	let currentRoute = DEFAULT_ROUTE_ID;
	if (location && location.pathname) {
		const baseRoute = location.pathname;
		const splitRoute = baseRoute.split("/");
		if (isArrayNotEmpty(splitRoute) && splitRoute[1] && hasOwnProperty(ID_TO_PATH_MAPPING, splitRoute[1])) {
			currentRoute = splitRoute[1];
		}
	}
	return currentRoute;
};

export default withRouter(SideNav);
