/* eslint-disable import/prefer-default-export */
import Constant from "../../utils/Constants";

const { ID_TO_PATH_MAPPING } = Constant;

/**
 * constant to store patterns for dynamic routes to hide side bar navigation
 */

export const HIDE_SIDEBAR_ROUTES = [
	/**
	 * Following regex is exact match for
	 * /adtag/create, /adtag/:id, /adtag/get/:id
	 */
	`^${ID_TO_PATH_MAPPING.adtag}/(\\w*/)*(\\d+|create|)$`,
	/**
	 * Following regex is exact match for
	 * /creativeLibrary/managepolicies, /creativeLibrary/:id
	 */
	`^${ID_TO_PATH_MAPPING.creativeLibrary}/(\\d+|managepolicies|)$`,
];
