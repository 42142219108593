import { useLocation } from "react-router-dom";
import { HIDE_SIDEBAR_ROUTES } from "../../containers/HomeContainer/constants";

/**
 * @param hiddenPatterns : custom path patterns to check for which side bar should not be visible
 * @returns boolean flag to toggle visibility of side bar
 */

const useHideSidebar = (hiddenPatterns: string[] = []): boolean => {
	const location = useLocation();
	const currentPath = location?.pathname;

	if (!currentPath) {
		return false;
	}

	const patternsToVerify = [...HIDE_SIDEBAR_ROUTES, ...(hiddenPatterns || [])];

	return patternsToVerify.some((pattern: string) => {
		if (!pattern) {
			return false;
		}
		const regex = new RegExp(pattern);
		return regex.test(currentPath);
	});
};

export default useHideSidebar;
